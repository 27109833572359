import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import { useLocation, useNavigate } from "react-router";
import "./Category.css";
import YouTube from "react-youtube";
import { useEffect, useState } from "react";
// import back from "../../Assests/Images/back.svg";
import close from "../../Assests/Images/close_sq.svg";
// import iconService from "../../Assests/Images/iconService.svg";
// import share from "../../Assests/Images/share.svg";
// import Header from "../Header";
import {
  Container,
  Navbar,
  Nav,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap";
import logo360 from "../../Assests/Images/temple_logo_dk.svg";
// import logoMinistry from "../../Assests/Images/darkMin.svg";
// import logoTutorial from "../../Assests/Images/darkTutorial.svg";
import logoAzadi from "../../Assests/Images/logo-azadi.svg";
// import search from "../../Assests/Images/search.svg";
import lang from "../../Assests/Images/lang_dark.svg";
import data from "../../Assests/Data/data.json";
import { useTranslation, Trans } from "react-i18next";

function VideoPlayer() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const lang = localStorage.getItem("language");
  const { state } = useLocation();

  const {
    title,
    category,
    desc,
    district,
    e_aarti,
    e_donation,
    e_prasad,
    e_shringaar,
    img,
    key,
    notice,
    states,
    vid,
    internal,
    language,
    offline,
    offline_vid,
  } = state;

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container fluid={"sm"}>
          <Navbar.Brand href="/">
            <img
              className="img-fluid mr-2"
              style={{ minWidth: "85px" }}
              src={logo360}
              alt="."
            ></img>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            {data?.map(
              (item) =>
                item?.lang === lang && (
                  <>
                    <Nav.Link className="headerTitle white" href="/">
                      {item?.data?.home}
                    </Nav.Link>
                    <Nav.Link className="headerTitle white" href="/about">
                      {item?.data?.about_head}
                    </Nav.Link>
                    {/* <Nav.Link
                      className="headerTitle white"
                      href="/inauguration"
                    >
                      {item?.data?.inauguration}
                    </Nav.Link> */}
                  </>
                )
            )}

            {/* <Nav.Link className="dark_head_title" href="/">
              {t("home")}
            </Nav.Link>

            <Nav.Link className="dark_head_title" href="/about">
              {t("about_head")}
            </Nav.Link> */}
            {/* <Nav.Link className="dark_head_title" href="/services">
              {t("services")}
            </Nav.Link> */}

            <Nav.Link className="logohide" href="/">
              <img className="img-fluid logo360" src={logoAzadi} alt="."></img>
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        // key={item.t.url}
        style={{
          position: "absolute",
          top: "5",
          right: "0",
          cursor: "pointer",
        }}
        onClick={() => {
          return navigate("/", {
            state: {
              title: title,
              desc: desc,
              district: district,
              e_aarti: e_aarti,
              e_donation: e_donation,
              e_prasad: e_prasad,
              e_shringaar: e_shringaar,
              img: img,
              key: key,
              notice: notice,
              states: state,
              vid: vid,
              internal: internal,
              category: category,
              key: key,
              language: language,
            },
          });
        }}
      >
        <img className="img-fluid" src={close} alt="." />
      </div>
      <div
        className="temple_name  text-center d-flex justify-content-center"
        style={{
          position: "absolute",
          backgroundColor: "#d84727",
          color: "#fff",
          border: "1px solid white",
          bottom: "0",
          right: "0",
          padding: "7px",
        }}
      >
        <h3 className="title_temple">{title}</h3>
      </div>
      <div
        // key={index}
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          zIndex: "-100000",
          overflow: "hidden",
        }}
      >
        <YouTube videoId={offline ? offline_vid : vid} opts={opts} />
      </div>
    </>
  );
}

export default VideoPlayer;
