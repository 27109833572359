import { Container, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./Home.css";
// import jyotirlingas from "./../Assests/Images/jyotirlingas.png";
// import dhams from "./../Assests/Images/dhams.png";
import jyotirlingas from "./../Assests/Images/jyotirlinga.png";
import dhams from "./../Assests/Images/dham.png";

import { useTranslation, Trans } from "react-i18next";
import data from "../Assests/Data/data.json";

function HomeCard({ all_temple, language }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/* <Container fluid={"sm"} className="home">
        <Row className=" align-items-center text-center">
          {data?.map(
            (item) =>
              item?.lang === language && (
                <div key={Math.random()}>
                  <h1 className=" hometxt home-title">
                    {item?.data?.home_intro_head}
                  </h1>
                  <br />

                  <p className="hometxt home-para pt-3">
                    {item?.data?.home_intro_subhead}
                  </p>
                  <br />
                </div>
              )
          )}
        </Row> 
      </Container> */}
      <Container>
        <Row>
          {all_temple?.map((top, index) => {
            return Object.values(top)?.map((item, index) => {
              return (
                item?.active && (
                  <Col
                    key={Math.random()}
                    className="d-flex justify-content-center "
                    sm={12}
                    md={6}
                    lg={4}
                  >
                    <Card
                      className="category-card mt-4"
                      key={Math.random()}
                      bg="black"
                      onClick={() => {
                        item.category === "jyotirlinga"
                          ? navigate("/temple/jyotirlinga/" + item.key, {
                              state: {
                                title: item?.title,
                                desc: item?.desc,
                                district: item?.district,
                                e_aarti: item?.e_aarti_link,
                                e_donation: item?.e_donation_link,
                                e_prasad: item?.e_prasad_link,
                                e_shringaar: item?.e_shringaar_link,
                                img: item?.img_url,
                                key: item?.key,
                                notice: item?.notice,
                                states: item?.state,
                                vid: item?.vid,
                                internal: item?.internal,
                                category: item?.category,
                                key: item?.key,
                                image_360: item?.image_360,
                                offline: item?.offline,
                                offline_vid: item?.offline_vid,
                                language: language,
                              },
                            })
                          : item.category === "dham"
                          ? navigate("/temple/dham/" + item.key, {
                              state: {
                                title: item?.title,
                                desc: item?.desc,
                                district: item?.district,
                                e_aarti: item?.e_aarti_link,
                                e_donation: item?.e_donation_link,
                                e_prasad: item?.e_prasad_link,
                                e_shringaar: item?.e_shringaar_link,
                                img: item?.img_url,
                                key: item?.key,
                                notice: item?.notice,
                                states: item?.state,
                                vid: item?.vid,
                                internal: item?.internal,
                                category: item?.category,
                                image_360: item?.image_360,
                                offline: item?.offline,
                                offline_vid: item?.offline_vid,
                                key: item?.key,
                                language: language,
                              },
                            })
                          : navigate("/temple/other/" + item.key, {
                              state: {
                                title: item?.title,
                                desc: item?.desc,
                                district: item?.district,
                                e_aarti: item?.e_aarti_link,
                                e_donation: item?.e_donation_link,
                                e_prasad: item?.e_prasad_link,
                                e_shringaar: item?.e_shringaar_link,
                                img: item?.img_url,
                                key: item?.key,
                                notice: item?.notice,
                                states: item?.state,
                                vid: item?.vid,
                                internal: item?.internal,
                                category: item?.category,
                                image_360: item?.image_360,
                                offline: item?.offline,
                                offline_vid: item?.offline_vid,
                                key: item?.key,
                                language: language,
                              },
                            });
                      }}
                    >
                      <div className="vignette-inset">
                        <Card.Img
                          className="category-img"
                          src={`https://storage.googleapis.com/static-temple360${(item?.img_url).slice(
                            1
                          )}`}
                          // src={item?.img_url}
                        ></Card.Img>
                      </div>
                      <Card.Body className="white pt-4">
                        <Card.Title className="categoryCard-title">
                          {item?.title}
                        </Card.Title>
                        <Card.Text className="categoryCard-para length-chars">
                          {item?.desc}
                        </Card.Text>
                        {data?.map(
                          (item) =>
                            item?.lang === language && (
                              <p
                                key={Math.random()}
                                className="pt-2 orange "
                                style={{ fontSize: "14px" }}
                              >
                                {item?.data?.tap_to_know_more}
                              </p>
                            )
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )
              );
            });
          })}
        </Row>
      </Container>
      <div className="mt-5"></div>

      <div className="lg-footer  d-flex align-items-center">
        {data?.map(
          (item) =>
            item?.lang === language && (
              <div
                className=""
                style={{ marginLeft: "6%" }}
                key={Math.random()}
              >
                <span
                  className="px-3 footer-text"
                  onClick={() => navigate("/terms-conditions")}
                >
                  {item.data.terms_conditions}
                </span>
                <span
                  className=" footer-text"
                  onClick={() => navigate("/privacy-policy")}
                >
                  {item.data.privacy_policy}
                </span>
              </div>
            )
        )}
      </div>
    </>
  );
}

export default HomeCard;

{
  /* <Row>
          {t("temp", {
            returnObjects: true,
          }).map((item, index) => (
            <Col
              key={index}
              className="d-flex justify-content-center "
              sm={12}
              md={6}
              lg={6}
            >
              <Card
                className="category-card mt-4"
                key={index}
                bg="black"
                onClick={() => {
                  item.category === "jyotirlinga"
                    ? navigate("/temple/jyotirlinga/" + item.key)
                    : navigate("/temple/dham/" + item.key);
                }}
                // onClick={() => {
                //   return navigate("/temple/jyotirlinga/" + item.key);
                // }}
              >
                <div className="vignette-inset">
                  <Card.Img
                    className="category-img"
                    src={item.img_url[0]}
                  ></Card.Img>
                </div>
                <Card.Body className="white pt-4">
                  <Card.Title className="categoryCard-title">
                    {item.title}
                  </Card.Title>
                  <Card.Text className="categoryCard-para length-chars">
                    {item.desc}
                  </Card.Text>
                  <p className="pt-2 orange " style={{ fontSize: "14px" }}>
                    {t("tap_to_know_more")}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row> */
}
