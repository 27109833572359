import "./Category.css";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import logo360 from "../../Assests/Images/temple_logo_dk.svg";
import logoMinistry from "../../Assests/Images/darkMin.svg";
import logoTutorial from "../../Assests/Images/darkTutorial.svg";
import logoAzadi from "../../Assests/Images/logo-azadi.svg";
import search from "../../Assests/Images/search.svg";
import { useTranslation, Trans } from "react-i18next";
import lang from "../../Assests/Images/lang_dark.svg";
import data from "../../Assests/Data/data.json";

function DarkHeader({ language }) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <Navbar variant="dark" className="m-2" expand="lg">
        <Container fluid={"sm"}>
          <Navbar.Brand href="/">
            <img className="img-fluid logo360 mr-2" src={logo360} alt="."></img>
            {/* <img
              className="img-fluid logo360 mx-2"
              src={logoMinistry}
              alt="."
            /> */}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            {/* <div className="search-box">
              <button className="btn-search">
                <img src={search} alt="."></img>
              </button>
              <input
                type="text"
                className="input-search "
                placeholder="Type to Search..."
              />
            </div> */}
            {/* <Nav.Link className="headerTitle white" href="/diya">
              <div className="add_diya">
                <span>{t("light_diya")}</span>
              </div>
            </Nav.Link> */}
            {data?.map(
              (item) =>
                item?.lang === language && (
                  <div className="taskbar" key={Math.random()}>
                    <Nav.Link className="headerTitle white" href="/">
                      {item?.data?.home}
                    </Nav.Link>
                    <Nav.Link className="headerTitle white" href="/about">
                      {item?.data?.about_head}
                    </Nav.Link>
                    {/* <Nav.Link
                      className="headerTitle white"
                      href="/inauguration"
                    >
                      {item?.data?.inauguration}
                    </Nav.Link> */}
                  </div>
                )
            )}
            {/* <Nav.Link className="headerTitle white" href="/">
              {t("home")}
            </Nav.Link>

            <Nav.Link className="headerTitle white" href="/about">
              {t("about_head")}
            </Nav.Link>
            <Nav.Link className="headerTitle white" href="/services">
              {t("services")}
            </Nav.Link> */}
            {/* <NavDropdown
              title={<img src={lang} alt="Language" />}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => changeLanguage("en")}>
                English
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeLanguage("hin")}>
                हिन्दी
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* <Nav.Link className="headerTitle white" href="/logIn">
              LogIn
            </Nav.Link> */}
            {/* <Nav.Link className="headerTitle" href="/tutorial">
              <img className="img-fluid logo360" src={logoTutorial} alt="." />
            </Nav.Link> */}
            <Nav.Link className="headerTitle" href="/">
              <img className="img-fluid logo360" src={logoAzadi} alt="."></img>
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default DarkHeader;
