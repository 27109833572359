import { Col, Container, Row, Card } from "react-bootstrap";
import Header from "./Header";
import "./Services.css";
import data from "../Data/service.json";
import { useNavigate } from "react-router";
import { useTranslation, Trans } from "react-i18next";

function Services() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <>
      <Header />

      <Container className="pt-5">
        <Row className=" align-items-center">
          <h1 className="service-head">{t("services")}</h1>

          <p className="service-para">{t("services_intro_subhead")}</p>
          <p className="service-para">{t("services_intro_head")}</p>
        </Row>
      </Container>
      <Container className="py-3">
        <Row className="d-flex justify-content-center pb-5">
          {t("service_data", {
            returnObjects: true,
          }).map((item, index) => {
            return (
              <Col sm={12} md={6} lg={3}>
                <Card
                  className="img-fluid service-card"
                  key={index}
                  onClick={() => {
                    navigate(`${item.page}`);
                  }}
                >
                  <Card.Img
                    className="img-fluid"
                    src={item.img_url}
                    alt="Card image"
                  />
                  <Card.ImgOverlay className="service-intro text-center text-justify px-4">
                    <Card.Title className="service-title">
                      {item.title}
                    </Card.Title>
                    <Card.Text className="service-text">{item.desc}</Card.Text>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <div className="footer"></div>
    </>
  );
}

export default Services;
