import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import Header from "./components/Header";
import "./components/servicesPortal/payment.css";
import { useTranslation, Trans } from "react-i18next";

function ComingSoon() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <>
      <Header />
      <Container fluid={"sm"}>
        <Row className="align-items-center" style={{ paddingTop: "20%" }}>
          <Col className="text-center">
            <h1 className="prasad-head">{t("coming_soon")}</h1>
            <button
              className="btn orangeBtn"
              onClick={() => {
                navigate("/");
              }}
            >
              {t("back_to_home")}
            </button>
          </Col>
        </Row>
      </Container>
      <div className="footer"></div>
    </>
  );
}

export default ComingSoon;
