import "./Header.css";
import { Navbar, Container, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import logo360 from "../Assests/Images/temple_logo_lg.svg";
import logoMoc from "../Assests/Images/moc.svg";
import logoAzadi from "../Assests/Images/logo-azadi.svg";
// import search from "../Assests/Images/search.svg";
// import dia_icon from "../Assests/Images/dia_icon.svg";
import { useTranslation, Trans } from "react-i18next";
import lang from "../Assests/Images/lang.svg";
import { useState } from "react";
import data from "../Assests/Data/data.json";

function Header({ langHandler }) {
  const { t, i18n } = useTranslation();

  const [head_lang, setHead_lang] = useState(localStorage.getItem("language"));

  const onClicklanguage = (e) => {
    localStorage.setItem("language", e.target.value);
    setHead_lang(e.target.value);
    // console.log(e.target.value, "coming from header");
    langHandler(e.target.value);
  };

  return (
    <>
      <Navbar className="m-2" expand="lg">
        <Container fluid={"sm"}>
          <Navbar.Brand href="/">
            <img
              className="img-fluid templeLogo mr-2"
              src={logo360}
              alt="."
            ></img>
          </Navbar.Brand>
          {/* <Navbar.Brand href="/">
            <img
              className="img-fluid templeLogo mr-2"
              src={logoMoc}
              alt="."
            ></img>
          </Navbar.Brand> */}

          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            {data?.map(
              (item) =>
                item?.lang === head_lang && (
                  <div className="taskbar" key={Math.random()}>
                    <Nav.Link className="headerTitle" href="/">
                      {item?.data?.home}
                    </Nav.Link>
                    <Nav.Link className="headerTitle" href="/about">
                      {item?.data?.about_head}
                    </Nav.Link>
                    {/* <Nav.Link className="headerTitle" href="/inauguration">
                      {item?.data?.inauguration}
                    </Nav.Link> */}
                  </div>
                )
            )}

            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <img src={lang} alt="Language" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <option onClick={onClicklanguage} value="eng">
                    English
                  </option>
                </Dropdown.Item>
                <Dropdown.Item>
                  <option onClick={onClicklanguage} value="hin">
                    हिन्दी
                  </option>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link className="headerTitle" href="/">
              <img className="img-fluid logo360" src={logoAzadi} alt="."></img>
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
