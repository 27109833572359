import {
  Col,
  Container,
  Row,
  Card,
  Navbar,
  Nav,
  Carousel,
} from "react-bootstrap";
import Header from "./Header";
import "./Services.css";
import { useTranslation, Trans } from "react-i18next";
import data from "../Assests/Data/data.json";
import logo360 from "../Assests/Images/temple_logo_lg.svg";
import logoAzadi from "../Assests/Images/logo-azadi.svg";

function Inauguration() {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("language");
  return (
    <>
      <Navbar className="m-2" expand="lg">
        <Container fluid={"sm"}>
          <Navbar.Brand href="/">
            <img
              className="img-fluid templeLogo mr-2"
              src={logo360}
              alt="."
            ></img>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            {data?.map(
              (item) =>
                item?.lang === lang && (
                  <>
                    <Nav.Link className="headerTitle" href="/">
                      {item?.data?.home}
                    </Nav.Link>
                    <Nav.Link className="headerTitle" href="/about">
                      {item?.data?.about_head}
                    </Nav.Link>
                    {/* <Nav.Link className="headerTitle" href="/inauguration">
                      {item?.data?.inauguration}
                    </Nav.Link> */}
                  </>
                )
            )}

            <Nav.Link className="headerTitle" href="/">
              <img className="img-fluid logo360" src={logoAzadi} alt="."></img>
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {data?.map(
        (item) =>
          item?.lang === lang && (
            <>
              <Container className="py-5 ">
                <Row className=" align-items-center">
                  <h1 className="service-head">
                    {item?.data?.inauguration_title}
                  </h1>
                </Row>
                <Row className="pt-5 about d-flex justify-content-center">
                  <p className="service-para">{item?.data?.inaug_text1}</p>
                  <p className="service-para">{item?.data?.inaug_text2}</p>
                  <p className="service-para">{item?.data?.inaug_text3}</p>
                </Row>
              </Container>
              <Container style={{ paddingBottom: "5%" }}>
                <Row>
                  <Col lg={6} md={12} className="pb-5">
                    <Carousel
                      key={Math.random()}
                      indicators={true}
                      interval={null}
                    >
                      <Carousel.Item>
                        <img
                          key={Math.random()}
                          className="d-block w-100 car_img img-fluid"
                          src={item?.data?.inaug_carousel[0].img}
                          alt=""
                          //   style={{ borderRadius: "10px", height: "45vh" }}
                        />
                        <Carousel.Caption className="caption">
                          <p className="car_caption">
                            {item?.data?.inaug_carousel[0].caption}
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          key={Math.random()}
                          className="d-block w-100 car_img img-fluid"
                          src={item?.data?.inaug_carousel[1].img}
                          alt="Second slide"
                          //   style={{ borderRadius: "10px", height: "45vh" }}
                        />
                        <Carousel.Caption className="caption">
                          <p className="car_caption">
                            {item?.data?.inaug_carousel[1].caption}
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          key={Math.random()}
                          className="d-block w-100 car_img img-fluid"
                          src={item?.data?.inaug_carousel[2].img}
                          alt="Second slide"
                          //   style={{ borderRadius: "10px", height: "45vh" }}
                        />
                        <Carousel.Caption className="caption">
                          <p className="car_caption">
                            {item?.data?.inaug_carousel[2].caption}
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          key={Math.random()}
                          className="d-block w-100 car_img img-fluid"
                          src={item?.data?.inaug_carousel[3].img}
                          alt="Second slide"
                          //   style={{ borderRadius: "10px", height: "45vh" }}
                        />
                        <Carousel.Caption className="caption">
                          <p className="car_caption">
                            {item?.data?.inaug_carousel[3].caption}
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          key={Math.random()}
                          className="d-block w-100 car_img img-fluid"
                          src={item?.data?.inaug_carousel[4].img}
                          alt="Second slide"
                          //   style={{ borderRadius: "10px", height: "45vh" }}
                        />
                        <Carousel.Caption className="caption">
                          <p className="car_caption">
                            {item?.data?.inaug_carousel[4].caption}
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          key={Math.random()}
                          className="d-block w-100 car_img img-fluid"
                          src={item?.data?.inaug_carousel[5].img}
                          alt="Second slide"
                          //   style={{ borderRadius: "10px", height: "45vh" }}
                        />
                        <Carousel.Caption className="caption">
                          <p className="car_caption">
                            {item?.data?.inaug_carousel[5].caption}
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </Col>
                  <Col lg={6} md={12} className="pb-5">
                    <div className="videoWrapper">
                      <iframe
                        width="560"
                        height="349"
                        className="img-fluid" //temple_video
                        src="https://www.youtube.com/embed/8kalivEMGt0?rel=0&amp;controls=1&amp&amp;showinfo=0&amp;modestbranding=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          )
      )}
      {/* <Container className="py-5 ">
        <Row className=" align-items-center">
          <h1 className="service-head">{t("about_head")}</h1>
        </Row>
        <Row className="pt-5 about">
          <p className="service-para">{t("about_intro_head")}</p>
          <ul>
            <li className="service-para">{t("e_prasad")}</li>
            <li className="service-para">{t("e_arti")}</li>
            <li className="service-para">{t("e_sringaar")}</li>
            <li className="service-para">{t("e_donation")}</li>
          </ul>
        </Row>
      </Container> */}

      <div className="footer"></div>
    </>
  );
}

export default Inauguration;
