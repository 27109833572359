import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import axios from "axios";
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import Header from "./Header";
import ImgCarousel from "./ImgCarousel";
import HomeCard from "./HomeCard";
import { useTranslation, Trans } from "react-i18next";
import { useEffect, useState } from "react";
import { Container, Card, Col, Row } from "react-bootstrap";

const firebaseConfig = {
  apiKey: "AIzaSyCdXrhVfKRDcqrECp9_ncS1Detxeya-HQ0",
  authDomain: "temple360-d7e77.firebaseapp.com",
  databaseURL:
    "https://temple360-d7e77-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "temple360-d7e77",
  storageBucket: "temple360-d7e77.appspot.com",
  messagingSenderId: "573809348712",
  appId: "1:573809348712:web:6c03d24b38874a256e2bdd",
  measurementId: "G-S7P2XHXQMS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfuSCUfAAAAAFpZQpGpqJvftwJ_9UM_7KrfDbvC"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

const db = getFirestore(app);
getAnalytics(app);

function Home() {
  const lang = localStorage.getItem("language");
  const templeListEng = [];
  const templeListHin = [];
  let count;
  const [allTempleEng, setAllTempleEng] = useState([]);
  const [allTempleHin, setAllTempleHin] = useState([]);
  const [language, setLanguage] = useState("hin");
  const [visit_count, setVisit_count] = useState();

  useEffect(() => {
    const res = axios.get(
      "https://asia-south1-temple360-d7e77.cloudfunctions.net/cGFnZXZpc2l0"
    );

    res
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [visit_count]);

  const visitor_count = async () => {
    const querySnapshot = await getDocs(collection(db, "visitors_count")).then(
      (res) =>
        res.forEach((doc) => {
          console.log(
            doc?._document?.data?.value?.mapValue?.fields?.count?.integerValue
          );
          setVisit_count(
            doc?._document?.data?.value?.mapValue?.fields?.count?.integerValue
          );
        })
    );
  };

  const get_temples_eng = async () => {
    const querySnapshot = await getDocs(
      query(collection(db, "temple_english"), orderBy("date", "desc"))
    )
      .then((res) =>
        res.forEach((doc) => {
          templeListEng.push({ [doc.id]: doc.data() });
        })
      )
      .then((value) => {
        setAllTempleEng(templeListEng);
      });
  };

  const get_temples_hin = async () => {
    const querySnapshot = await getDocs(
      query(collection(db, "temple_hindi"), orderBy("date", "desc"))
    )
      .then((res) =>
        res.forEach((doc) => {
          templeListHin.push({ [doc.id]: doc.data() });
        })
      )
      .then((value) => {
        setAllTempleHin(templeListHin);
      });
  };

  const langHandler = (data) => {
    setLanguage(data);
  };

  useEffect(() => {
    visitor_count();
    get_temples_eng();
    get_temples_hin();
    setLanguage(lang);
  }, []);

  return (
    <>
      {/* <h1>{visit_count}</h1> */}
      <Header langHandler={langHandler} />
      <ImgCarousel language={language} visitCount={visit_count} />
      <HomeCard
        all_temple={language === "hin" ? allTempleHin : allTempleEng}
        language={language}
      />
    </>
  );
}

export default Home;
