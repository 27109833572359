import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import Services from "./components/Services";
import Home from "./components/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Prasad from "./components/servicesPortal/prasad";
// import Arti from "./components/servicesPortal/arti";
// import Shringar from "./components/servicesPortal/shringar";
// import Donation from "./components/servicesPortal/donation";
import Jyotirlinga from "./components/category/Jyotirlinga";
import Dham from "./components/category/Dham";
import Category from "./components/category/Category";
import VideoPlayer from "./components/category/VideoPlayer";
import LogIn from "./components/LogIn";
import ComingSoon from "./ComingSoon";
import "./i18n.js";

// ---------------------------------------------------Firestore------------------------------------------------------

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import About from "./components/About";
import Terms from "./components/Terms";
import Privacypolicy from "./components/Privacypolicy";
import Inauguration from "./components/Inauguration";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyCdXrhVfKRDcqrECp9_ncS1Detxeya-HQ0",
  authDomain: "temple360-d7e77.firebaseapp.com",
  databaseURL:
    "https://temple360-d7e77-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "temple360-d7e77",
  storageBucket: "temple360-d7e77.appspot.com",
  messagingSenderId: "573809348712",
  appId: "1:573809348712:web:6c03d24b38874a256e2bdd",
  measurementId: "G-S7P2XHXQMS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfuSCUfAAAAAFpZQpGpqJvftwJ_9UM_7KrfDbvC"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
getAnalytics(app);

// ---------------------------------------------------Firestore------------------------------------------------------

{
  !localStorage.getItem("language") && localStorage.setItem("language", "eng");
}

// localStorage.setItem("language", "eng");

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/logIn" exact element={<LogIn />} />
      <Route path="/jyotirlinga" exact element={<Jyotirlinga />} />
      <Route path="/dham" exact element={<Dham />} />
      <Route path="/temple/:category/:temple" exact element={<Category />} />
      <Route
        path="/camera/:category/:temple/:camera"
        exact
        element={<VideoPlayer />}
      />
      <Route path="/about" exact element={<About />} />
      {/* <Route path="/inauguration" exact element={<Inauguration />} /> */}
      <Route path="/terms-conditions" exact element={<Terms />} />
      <Route path="/privacy-policy" exact element={<Privacypolicy />} />
      <Route path="/services" exact element={<Services />} />
      <Route path="/arti" exact element={<ComingSoon />} />
      <Route path="/prasad" exact element={<ComingSoon />} />
      <Route path="/shringar" exact element={<ComingSoon />} />
      <Route path="/donation" exact element={<ComingSoon />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
