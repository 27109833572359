import Header from "./Header";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import "./LogIn.css";

function LogIn() {
  return (
    <>
      <Header />
      <Container fluid={"sm"}>
        <Row className="align-items-center pt-5">
          <Col lg={4}></Col>
          <Col lg={4} className="align-items-center text-center">
            <p className="signHead">Sign-In</p>
            <p className="signNote">Enter your details below to continue</p>
            <Form className="signForm text-center">
              <Form.Group
                as={Row}
                className="mb-3 text-center"
                controlId="formPlaintextEmail"
              >
                <Col>
                  <Form.Control
                    className="signInput"
                    type="email"
                    placeholder="E-mail"
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Col>
                  <Form.Control
                    className="signInput"
                    type="password"
                    placeholder="Password"
                  />
                </Col>
              </Form.Group>
              <p className="orange text text-center">Forgot Password?</p>
              <button className="loginBtn text">Login</button>
              <p className="note py-4">
                Don’t have account?
                <span className="orange"> Sign up here </span>{" "}
              </p>
            </Form>
            <p>Sign In with</p>
            <Row>
              <Col style={{ textAlign: "right" }}>
                <img src="./imgservice/fb.svg"></img>
              </Col>
              <Col style={{ textAlign: "left" }}>
                <img src="./imgservice/google.svg"></img>
              </Col>
            </Row>
          </Col>
          <Col lg={4}></Col>
        </Row>
      </Container>
      <div className="footer"></div>
    </>
  );
}

export default LogIn;
