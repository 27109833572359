import { Col, Container, Row, Card, Navbar, Nav } from "react-bootstrap";
import Header from "./Header";
import "./Services.css";
import { useTranslation, Trans } from "react-i18next";
import data from "../Assests/Data/data.json";
import logo360 from "../Assests/Images/temple_logo_lg.svg";
import logoAzadi from "../Assests/Images/logo-azadi.svg";
import { useEffect } from "react";

function Terms() {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("language");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar className="m-2" expand="lg">
        <Container fluid={"sm"}>
          <Navbar.Brand href="/">
            <img
              className="img-fluid templeLogo mr-2"
              src={logo360}
              alt="."
            ></img>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            {data?.map(
              (item) =>
                item?.lang === lang && (
                  <>
                    <Nav.Link className="headerTitle" href="/">
                      {item?.data?.home}
                    </Nav.Link>
                    <Nav.Link className="headerTitle" href="/about">
                      {item?.data?.about_head}
                    </Nav.Link>
                  </>
                )
            )}

            <Nav.Link className="headerTitle" href="/">
              <img className="img-fluid logo360" src={logoAzadi} alt="."></img>
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="py-5 ">
        <Row className=" align-items-center">
          <h1 className="service-head">{data[0]?.data?.terms_conditions}</h1>
        </Row>
        <Row className="pt-5 about">
          <p className="service-para">{data[0]?.data?.term_text}</p>
        </Row>
      </Container>

      {/* {data?.map(
        (item) =>
          item?.lang === lang && (
            <Container className="py-5 ">
              <Row className=" align-items-center">
                <h1 className="service-head">{item?.data?.terms_conditions}</h1>
              </Row>
              <Row className="pt-5 about">
                <p className="service-para">{item?.data?.term_text}</p>
              </Row>
            </Container>
          )
      )} */}

      <div className="footer"></div>
    </>
  );
}

export default Terms;
