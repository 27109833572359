import { Container, Row, Col, Carousel } from "react-bootstrap";
import play from "../../Assests/Images/play.svg";
import prev from "../../Assests/Images/prev.svg";
import next from "../../Assests/Images/next.svg";
import DarkHeader from "./DarkHeader";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useLocation, useNavigate } from "react-router";
import data from "../../Assests/Data/data.json";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useState, useEffect } from "react";
import { Pannellum } from "pannellum-react";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import close from "../../Assests/Images/close_sq.svg";

function Category() {
  const [is360, setIs360] = useState(false);
  const [screenSize, setScreenSize] = useState("");
  const [carouselImg, setCarouselImg] = useState(1);
  const navigate = useNavigate();
  const { state } = useLocation();
  // let imgArr = [
  //   "00",
  //   "01",
  //   "02",
  //   "03",
  //   "04",
  //   "05",
  //   "06",
  //   "07",
  //   "08",
  //   "9",
  //   "10",
  //   "11",
  // ];
  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);
  useEffect(() => {
    // console.log(carouselImg);
  }, [carouselImg]);
  const {
    title,
    category,
    desc,
    district,
    e_aarti,
    e_donation,
    e_prasad,
    e_shringaar,
    img,
    key,
    notice,
    states,
    vid,
    internal,
    language,
    image_360,
    offline,
    offline_vid,
  } = state;
  const { t, i18n } = useTranslation();

  const location = window.location.href.split("/", 6);
  const selectedCat = location[location.length - 2];
  const selectedTop = location[location.length - 1];
  const [imgA, setImgA] = useState([]);

  useEffect(() => {
    console.log(i18n.lang);
  }, [i18n.lang]);

  const [imgSrc, setImgSrc] = useState("");
  console.log(language);
  // Converting from path to File object

  const srcToFile = async (src, fileName) => {
    const response = await axios.get(src, {
      responseType: "blob",
    });
    const mimeType = response.headers["content-type"];
    return new File([response.data], fileName, { type: mimeType });
  };

  // RESIZING 360 IMAGE

  function fileChangedHandler(event) {
    try {
      Resizer.imageFileResizer(
        event,
        4096,
        2048,
        "JPEG",
        100,
        0,
        (uri) => {
          setImgSrc(uri);
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
    }
  }

  // To Check if Its a android device or not

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  function handleLoad() {
    if (getMobileOperatingSystem() !== "Android") {
      const res = srcToFile(
        `https://storage.googleapis.com/static-temple360/360/${key}/${carouselImg}.jpg`,
        "abc.jpg"
      );

      res.then((result) => fileChangedHandler(result));
    } else {
      setImgSrc(
        `https://storage.googleapis.com/static-temple360/360/${key}/${carouselImg}.jpg`
      );
    }
  }

  function handleNext() {
    if (carouselImg == image_360) {
      setCarouselImg(1);
    } else {
      setCarouselImg(carouselImg + 1);
    }
    if (getMobileOperatingSystem() !== "Android") {
      const res = srcToFile(
        `https://storage.googleapis.com/static-temple360/360/${key}/${carouselImg}.jpg`
      );

      res.then((result) => fileChangedHandler(result));
    } else {
      setImgSrc(
        `https://storage.googleapis.com/static-temple360/360/${key}/${carouselImg}.jpg`
      );
    }
  }

  function handlePrev() {
    if (carouselImg == 1) {
      setCarouselImg(image_360);
    } else {
      setCarouselImg(carouselImg - 1);
    }

    if (getMobileOperatingSystem() !== "Android") {
      const res = srcToFile(
        `https://storage.googleapis.com/static-temple360/360/${key}/${carouselImg}.jpg`,
        "abc.jpg"
      );

      res.then((result) => fileChangedHandler(result));
    } else {
      setImgSrc(
        `https://storage.googleapis.com/static-temple360/360/${key}/${carouselImg}.jpg`
      );
    }
  }
  // `https://storage.googleapis.com/static-temple360/360/${key}/${
  //   carouselImg < 10 ? "0" + carouselImg : carouselImg
  // }.jpg`
  let imgArr = [];
  useEffect(() => {
    const imgArray = (temp) => {
      for (var i = 1; i <= temp; i++) {
        if (i <= 9) {
          imgArr.push("0" + i);
        } else {
          imgArr.push(i.toString());
        }
      }
    };
    setImgA(imgArr);
    imgArray(parseInt(image_360));
    handleLoad();
  }, []);

  return (
    <>
      <div
        key={key}
        className="img-fluid background_img background-tint"
        style={{
          backgroundImage: `url(https://storage.googleapis.com/static-temple360${img.slice(
            1
          )})`,
          width: "100vw",
        }}
      >
        <DarkHeader language={language} />
        {!is360 ? (
          <Container fluid="xxl">
            <Row className="align-items-center pt-5">
              <Col className="categoryIntro">
                <p className="categoryTitle">{title}</p>
                <div className="categoryDescription ">
                  {desc}
                  <br />
                  {data?.map(
                    (item, index) =>
                      item?.lang === language && (
                        <>
                          <div key={index}>
                            {item?.data?.district} : {district}
                            <br />
                            {item?.data?.state} : {states}
                          </div>
                          <div>
                            <p className="categoryDescription ">{notice}</p>
                          </div>
                        </>
                      )
                  )}
                </div>
                {internal ? (
                  <button
                    className="watchBtn mx-2"
                    onClick={() => {
                      return navigate(
                        "/camera/" + category + "/" + key + "/cam1",
                        {
                          state: {
                            title: title,
                            desc: desc,
                            district: district,
                            e_aarti: e_aarti,
                            e_donation: e_donation,
                            e_prasad: e_prasad,
                            e_shringaar: e_shringaar,
                            img: img,
                            key: key,
                            notice: notice,
                            states: state,
                            vid: vid,
                            internal: internal,
                            category: category,
                            key: key,
                            language: language,
                            offline: offline,
                            offline_vid: offline_vid,
                            data: data,
                          },
                        }
                      );
                    }}
                  >
                    <div className=" align-items-center text text-center">
                      <span className="d-flex justify-content-center">
                        {data?.map(
                          (item, index) =>
                            item?.lang === language && (
                              <p key={index}>{item?.data?.watch}</p>
                            )
                        )}

                        <PlayArrowIcon />
                      </span>
                    </div>
                  </button>
                ) : (
                  <a href={vid} target={"_blank"}>
                    <button className="watchBtn mx-2">
                      <div className=" align-items-center text text-center">
                        <span className="d-flex justify-content-center">
                          {data?.map(
                            (item, index) =>
                              item?.lang === language && (
                                <p key={index}>{item?.data?.watch} </p>
                              )
                          )}

                          <PlayArrowIcon />
                        </span>
                      </div>
                    </button>
                  </a>
                )}

                {e_donation && (
                  <>
                    &nbsp;
                    <a href={e_donation} target={"_blank"}>
                      <button className="watchBtn servicesBtn mx-2 my-2">
                        <div className=" align-items-center text text-center">
                          <span className="d-flex justify-content-center">
                            {data?.map(
                              (item, index) =>
                                item?.lang === language && (
                                  <>
                                    <p key={index}>{item?.data?.e_donation} </p>
                                  </>
                                )
                            )}
                          </span>
                        </div>
                      </button>
                    </a>
                  </>
                )}

                {e_aarti && (
                  <>
                    &nbsp;
                    <a href={e_aarti} target={"_blank"}>
                      <button className="watchBtn servicesBtn  mx-2  my-2">
                        <div className=" align-items-center text text-center">
                          <span className="d-flex justify-content-center">
                            {data?.map(
                              (item, index) =>
                                item?.lang === language && (
                                  <>
                                    <p key={index}>{item?.data?.e_aarti} </p>
                                  </>
                                )
                            )}
                          </span>
                        </div>
                      </button>
                    </a>
                  </>
                )}

                {e_prasad && (
                  <>
                    &nbsp;
                    <a href={e_prasad} target={"_blank"}>
                      <button className="watchBtn servicesBtn  mx-2  my-2">
                        <div className=" align-items-center text text-center">
                          <span className="d-flex justify-content-center">
                            {data?.map(
                              (item, index) =>
                                item?.lang === language && (
                                  <p key={index}>{item?.data?.e_prasad} </p>
                                )
                            )}
                          </span>
                        </div>
                      </button>
                    </a>
                  </>
                )}

                {e_shringaar && (
                  <>
                    &nbsp;
                    <a href={e_shringaar} target={"_blank"}>
                      <button className="watchBtn servicesBtn  mx-2  my-2">
                        <div className=" align-items-center text text-center">
                          <span className="d-flex justify-content-center">
                            {data?.map(
                              (item, index) =>
                                item?.lang === language && (
                                  <p key={index}>{item?.data?.e_shringaar} </p>
                                )
                            )}
                          </span>
                        </div>
                      </button>
                    </a>
                  </>
                )}

                {imgA?.length > 0 && (
                  <>
                    &nbsp;
                    <button
                      className="watchBtn servicesBtn  mx-2  my-2"
                      onClick={() => setIs360(true)}
                    >
                      <div className=" align-items-center text text-center">
                        <span className="d-flex justify-content-center">
                          {data?.map(
                            (item, index) =>
                              item?.lang === language && (
                                <p key={index}>{item?.data?.img_360}</p>
                              )
                          )}
                        </span>
                      </div>
                    </button>
                  </>
                )}
              </Col>
              <Col className="categoryCamera" lg={4} md={12} sm={12}>
                {internal ? (
                  <Col>
                    <div
                      className="categoryVid img-fluid  mt-5"
                      style={{
                        backgroundImage: `url(https://storage.googleapis.com/static-temple360${img.slice(
                          1
                        )})`,
                      }}
                      onClick={() => {
                        return navigate(
                          "/camera/" + category + "/" + key + "/cam1",
                          {
                            state: {
                              title: title,
                              desc: desc,
                              district: district,
                              e_aarti: e_aarti,
                              e_donation: e_donation,
                              e_prasad: e_prasad,
                              e_shringaar: e_shringaar,
                              img: img,
                              key: key,
                              notice: notice,
                              states: state,
                              vid: vid,
                              internal: internal,
                              category: category,
                              key: key,
                              language: language,
                              offline: offline,
                              offline_vid: offline_vid,
                              data: data,
                            },
                          }
                        );
                      }}
                    >
                      <div style={{ position: "absolute" }}>
                        <img src={play} alt="." />
                      </div>
                    </div>
                  </Col>
                ) : (
                  <a href={vid} target={"_blank"}>
                    <Col>
                      <div
                        className="categoryVid img-fluid  mt-5"
                        style={{
                          backgroundImage: `url(https://storage.googleapis.com/static-temple360${img.slice(
                            1
                          )})`,
                        }}
                      >
                        <div style={{ position: "absolute" }}>
                          <img src={play} alt="." />
                        </div>
                      </div>
                    </Col>
                  </a>
                )}
              </Col>
              ;
            </Row>

            {/* {e_donation || e_shringaar || e_prasad || e_aarti ? (
            <Row>
              <Tabs>
                <TabList>
                  {e_donation && (
                    <Tab>
                      <div className=" ">
                        <span className="d-flex justify-content-center">
                          {data?.map(
                            (item, index) =>
                              item?.lang === language && (
                                <>
                                  <p key={index}>{item?.data?.e_donation} </p>
                                </>
                              )
                          )}
                        </span>
                      </div>
                    </Tab>
                  )}
                  {e_shringaar && (
                    <Tab>
                      {" "}
                      <div className=" align-items-center text text-center">
                        <span className="d-flex justify-content-center">
                          {data?.map(
                            (item, index) =>
                              item?.lang === language && (
                                <p key={index}>{item?.data?.e_shringaar} </p>
                              )
                          )}
                        </span>
                      </div>
                    </Tab>
                  )}
                  {e_prasad && (
                    <Tab>
                      {" "}
                      <div className=" align-items-center text text-center">
                        <span className="d-flex justify-content-center">
                          {data?.map(
                            (item, index) =>
                              item?.lang === language && (
                                <p key={index}>{item?.data?.e_prasad} </p>
                              )
                          )}
                        </span>
                      </div>
                    </Tab>
                  )}
                  {e_aarti && (
                    <Tab>
                      <button className="watchBtn servicesBtn my-2">
                        <div className=" align-items-center text text-center">
                          <span className="d-flex justify-content-center">
                            {data?.map(
                              (item, index) =>
                                item?.lang === language && (
                                  <>
                                    <p key={index}>{item?.data?.e_aarti} </p>
                                  </>
                                )
                            )}
                          </span>
                        </div>
                      </button>
                    </Tab>
                  )}
                </TabList>

                <TabPanel>
                  <iframe
                    className="categoryIframe img-fluid "
                    src={e_donation}
                    title="E-donation"
                  ></iframe>
                </TabPanel>
                <TabPanel>
                  <iframe
                    className="categoryIframe img-fluid "
                    src={e_shringaar}
                    title="E-shringaar"
                  ></iframe>
                </TabPanel>
                <TabPanel>
                  <iframe
                    className="categoryIframe img-fluid "
                    src={e_prasad}
                    title="E-prasad"
                  ></iframe>
                </TabPanel>
                <TabPanel>
                  <iframe
                    className="categoryIframe img-fluid "
                    src={e_aarti}
                    title="E-aarti"
                  ></iframe>
                </TabPanel>
              </Tabs>
            </Row>
          ) : null} */}
          </Container>
        ) : (
          <>
            <Container
              style={{
                top: "50%",
                zIndex: "10000",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Row>
                <Col
                  className="orange"
                  style={{
                    float: "left",
                  }}
                >
                  <img
                    className="img-fluid "
                    style={{ height: "5vh", cursor: "pointer" }}
                    src={prev}
                    alt="prev"
                    onClick={() => {
                      handlePrev();
                    }}
                  />
                </Col>
                <Col
                  className="orange"
                  style={{
                    textAlign: "end",
                  }}
                >
                  <img
                    className="img-fluid"
                    style={{ height: "5vh", cursor: "pointer" }}
                    src={next}
                    alt="next"
                    onClick={() => {
                      handleNext();
                    }}
                  />
                </Col>
              </Row>
            </Container>
            <Container>
              <div
                // key={item.t.url}
                style={{
                  position: "absolute",
                  top: "5",
                  right: "0",
                  cursor: "pointer",
                  zIndex: "10000",
                }}
                onClick={() => setIs360(false)}
              >
                <img className="img-fluid" src={close} alt="." />
              </div>
              <div className="d-flex justify-content-center">
                <Pannellum
                  width="90vw"
                  height="80vh"
                  image={imgSrc}
                  pitch={10}
                  yaw={180}
                  hfov={100}
                  autoLoad
                  loop
                  autoRotate={+5}
                  showZoomCtrl={true}
                  // onLoad={() => {
                  //   console.log(
                  //     `https://storage.googleapis.com/static-temple360/360/${key}/${
                  //       carouselImg < 10 ? "0" + carouselImg : carouselImg
                  //     }.jpg`
                  //   );
                  // }}
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    // zIndex: "10000",
                  }}
                ></Pannellum>
              </div>
            </Container>
          </>
        )}
      </div>
      {/* )
            );
          })
        );
      })} */}

      <div className="footer"></div>
    </>
  );
}

export default Category;
