import { Carousel } from "react-bootstrap";
import "./Home.css";
import data from "../Assests/Data/data.json";
import { useTranslation, Trans } from "react-i18next";

function ImgCarousel({ language, visitCount }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      {data?.map(
        (item, index) =>
          item?.lang === language && (
            <Carousel key={Math.random()} indicators={true} interval={null}>
              <Carousel.Item>
                <img
                  key={index}
                  className="d-block w-100 img-fluid"
                  src={item?.data?.carousel[0]}
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  key={index}
                  className="d-block w-100 img-fluid"
                  src={item?.data?.carousel[1]}
                  alt="Second slide"
                />
              </Carousel.Item>
            </Carousel>
          )
      )}

      <div
        className="container temple-cont"
        // style={{
        //   marginTop: "12%",
        //   marginBottom: "5%",
        //   borderRadius: "10px",
        // }}
      >
        <div className="row d-flex align-items-center">
          <div className="col-lg-7 col-md-12  col-sm-12">
            <div className="videoWrapper">
              <iframe
                width="560"
                height="349"
                className="img-fluid " //temple_video
                src="https://www.youtube.com/embed/t7qYk_HwJQw?rel=0&amp;controls=1&amp&amp;showinfo=0&amp;modestbranding=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 home_sub_cont">
            {data?.map(
              (item) =>
                item?.lang === language && (
                  <>
                    {visitCount && (
                      <div className="visitor_cont">
                        <h3 className="visitor">{visitCount}</h3>
                        <p className="visit_p">{item?.data?.people_visited}</p>
                      </div>
                    )}

                    <div key={Math.random()}>
                      <h1 className=" hometxt home-title">
                        {item?.data?.home_intro_head}
                      </h1>
                      <br />

                      {/* <p className="hometxt home-para pt-3">
                        {item?.data?.home_intro_subhead}
                      </p> */}
                      <br />
                    </div>
                  </>
                )
            )}
          </div>
        </div>

        {/* <div className="align-items-center">
          <video
            loop
            muted
            autoPlay
            controls="0"
            className="temple_video img-fluid"
            // poster="/templeImg/Badrinath.jpg"
          >
            <source src="./vid_temple.mp4" type="video/mp4" />
          </video>
        </div> */}
      </div>
    </>
  );
}

export default ImgCarousel;
