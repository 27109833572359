import "./Category.css";
import Header from "../Header";
import data from "../../Data/temples.json";
import { Col, Container, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import intro from "../../Assests/Images/dham_intro.jpg";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";

function Dham() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <>
      <Header />

      <Container
        fluid
        style={{
          backgroundImage: `url(${intro})`,
          // height: "30rem",
          // overflow: "scroll",
        }}
        className="py-5 img-fluid"
      >
        <Row className="align-items-center">
          <Col lg={6}>
            <div className="p-4 text-start">
              <h1 className="category-head white">
                {
                  t("data", {
                    returnObjects: true,
                  })[1].topic
                }
              </h1>
              {show ? (
                <div class="expandable" id="nav">
                  <p className="category-para white">
                    {
                      t("data", {
                        returnObjects: true,
                      })[1].description
                    }
                  </p>
                  <a
                    className="orange"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <span>{t("read_less")}</span>
                  </a>
                </div>
              ) : (
                <>
                  <p className="category-para white">
                    {
                      t("data", {
                        returnObjects: true,
                      })[1].desc
                    }
                  </p>
                  <a
                    className="orange"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    <span>{t("read_more")}</span>
                  </a>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className=" py-5">
        <Row className="align-items-center">
          {t("data", {
            returnObjects: true,
          })[1].content.map((item, index) => {
            return (
              <>
                <Col
                  className="d-flex justify-content-center"
                  sm={12}
                  md={6}
                  lg={4}
                >
                  <Card
                    className="category-card mt-4"
                    bg="black"
                    key={index}
                    onClick={() => {
                      return navigate("/temple/dham/" + item.key);
                    }}
                  >
                    <div className="vignette-inset">
                      <Card.Img
                        className="category-img"
                        src={item.img_url[0]}
                        alt="."
                      ></Card.Img>
                    </div>
                    <Card.Body className="white pt-4">
                      <Card.Title className="categoryCard-title">
                        {item.title}
                      </Card.Title>
                      <Card.Text className="categoryCard-para length-chars">
                        {item.desc}
                      </Card.Text>
                      <p className="pt-2 orange " style={{ fontSize: "14px" }}>
                        {t("tap_to_know_more")}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

export default Dham;
